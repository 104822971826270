.active-inventory-item:hover:not(:has(.inventory-item-sub-button:hover)) > * {
  @apply !bg-neutral-01-50;
}

.active-inventory-item:hover:not(:has(.inventory-item-sub-button:hover)) .cta {
  @apply !border-primary-01-950 !bg-white !text-primary-01-950;
}

.active-inventory-item:hover:not(:has(.inventory-item-sub-button:hover)) .inventory-item-image {
  @apply !opacity-90;
}
